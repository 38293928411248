import { Link } from 'react-router-dom';

const mainPath = 'directory/companies-insights/uk';

export const UK_PRIVATE_COMPANY_INSIGHTS_PATH = `/${mainPath}`;
export const UK_PRIVATE_COMPANY_INSIGHTS_PATH_MAIN = mainPath;

export const UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS = [
  {
    name: 'UK Private Company Insights',
    path: mainPath,
  },
];

export const NATURE_OF_BUSINESS_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Sectors',
    path: `${mainPath}/nature-of-business-sectors`,
  },
];

export const TOP_EQUITY_FUNDRAISERS_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Top Equity Fundraisers',
    path: `${mainPath}/top-equity-fundraisers`,
  },
];

export const TOP_MARKET_CAPITALISATION_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Top Companies by Last Round Valuation',
    path: `${mainPath}/top-companies-by-market-capitalisation`,
  },
];

export const COMPANIES_HIGHEST_ROI_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Companies with highest ROI',
    path: `${mainPath}/companies-with-highest-roi`,
  },
];

export const TOP_INVESTORS_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Top Investors Holdings',
    path: `${mainPath}/top-investors`,
  },
];

export const CHANGES_IN_COMPANY_STATUS_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Changes in Company Status',
    path: `${mainPath}/changes-in-company-status`,
  },
];

export const STATUS_FOR_ALL_COMPANIES_BREADCRUMBS = [
  ...UK_PRIVATE_COMPANY_INSIGHTS_BREADCRUMBS,
  {
    name: 'Status for All Companies',
    path: `${mainPath}/status-for-all-companies`,
  },
];

export const chartPeriodFilters = [
  {
    value: '1d',
    text: '1D',
    tooltip: '1 day',
    active: false,
  },
  {
    value: '1w',
    text: '1W',
    tooltip: '1 week',
    active: true,
  },
  {
    value: '1m',
    text: '1M',
    tooltip: '1 month',
    active: false,
  },
  {
    value: '3m',
    text: '3M',
    tooltip: '3 month',
    active: false,
  },
  {
    value: '1y',
    text: '1Y',
    tooltip: '1 year',
    active: false,
  },
  {
    value: 'all',
    text: 'All',
    tooltip: 'All',
    active: false,
  },
];

export const TopEquityFundraisersFields = [
  {
    field: 'name',
    title: 'Company Name',
    className: 'text-wrap text-left company_name',
    type: 'link-advanced',
    skipSorting: true,
  },
  {
    field: 'natureOfBusiness',
    title: 'Nature of Business',
    type: 'text',
    isArr: true,
    className: 'text-wrap text-left nature_business',
    tooltip: "The company's nature of business as reported to Companies House.",
    skipSorting: true,
  },
  {
    field: 'currency',
    title: 'Currency',
    type: 'text',
    className: 'text-wrap text-left currency',
    skipSorting: true,
  },
  {
    field: 'raisedAmount',
    title: 'Funds Raised',
    type: 'amount',
    className: 'raised_amount',
    shouldComputed: true,
    allowDynamicRound: true,
    tooltip: 'The aggregate funds raised for the specified year.',
    skipSorting: true,
  },
];

export const TopMarketCapitalisationFields = [
  {
    field: 'name',
    title: 'Company Name',
    className: 'text-wrap text-left company_name',
    type: 'link-advanced',
    skipSorting: true,
  },
  {
    field: 'natureOfBusiness',
    title: 'Nature of Business',
    type: 'text',
    className: 'text-wrap text-left nature_business',
    skipSorting: true,
  },
  {
    field: 'currency',
    title: 'Currency',
    type: 'text',
    className: 'text-wrap text-left currency',
    skipSorting: true,
  },
  {
    field: 'marketCapitalization_text',
    title: 'Last Round Valuation',
    type: 'text',
    className: 'market_capitalization',
    skipSorting: true,
  },
];

export const companiesHighestROIFields = [
  {
    field: 'name',
    title: 'Company Name',
    type: 'link-advanced',
    className: 'text-wrap text-left company_name',
    skipSorting: true,
  },
  {
    field: 'natureOfBusiness',
    title: 'Nature of Business',
    type: 'text',
    className: 'text-wrap text-left nature_business',
    tooltip: "The company's nature of business as reported to Companies House.",
    skipSorting: true,
  },
  {
    field: 'currency',
    title: 'Currency',
    type: 'text',
    className: 'text-wrap text-left currency',
    skipSorting: true,
  },
  {
    field: 'returnOnInvestment',
    title: 'Return On Investment',
    type: 'percentage',
    className: 'return_on_investment',
    skipColor: true,
    skipSorting: true,
    tooltip: 'The year-to-date return on investment (YTD ROI).',
  },
];

export const TopInvestorsFields = [
  {
    field: 'name',
    title: 'Investors',
    type: 'link-advanced',
    className: 'text-wrap text-left investor_name',
    skipSorting: true,
  },
  {
    field: 'investmentsMade',
    title: 'Number of Investments',
    type: 'text',
    shouldComputed: true,
    reverseSort: true,
    tooltip: 'The total number of investments currently held by the investor.',
  },
  {
    field: 'currency',
    title: 'Currency',
    type: 'text',
    skipSorting: true,
    className: 'text-wrap text-left currency',
  },
  {
    field: 'valueOfHoldings',
    title: 'Value',
    type: 'amount',
    shouldComputed: true,
    reverseSort: true,
    tooltip: 'The aggregate value of the investments currently held by the investor.',
  },
];

export const topGBPMinKeys = {
  fundraising: {
    ytd: 'raisedAmount',
    year: 'raisedAmountPrevious',
    urlLink: 'companyPermalink',
  },
  investors: {
    ytd: 'valueOfHoldings',
    year: 'amountInvestedPrevious',
    change: 'amountInvestedChange',
    urlLink: 'investorPermalink',
  },
  market: {
    ytd: 'marketCapitalization',
    year: 'marketCapitalizationPrevious',
    change: 'change',
    urlLink: 'companyPermalink',
  },
  roi: {
    ytd: 'returnOnInvestment',
    year: 'returnOnInvestmentPrevious',
    urlLink: 'companyPermalink',
  },
};

export const defaultEquityChartBarData = {
  // set numbers as x values to got linear x-axis
  // and set actual values using tickers below with layout
  x: [],
  y: [],
  type: 'bar',
  marker: {
    color: '#88AADA',
  },
  hoverinfo: 'none',
};

export const defaultEquityChartLayoutData = {
  autosize: true,
  dragmode: false,
  hovermode: 'x',
  xaxis: {
    fixedrange: true,
    type: 'date',
    zeroline: false,
    tickfont: {
      size: 12,
      family: 'Lato',
      color: '#666666',
    },
    mirror: 'ticks',
    linecolor: '#c0c0c0',
    linewidth: 1,
    tickformat: '%d %b<br />%Y',
    cliponaxis: false,
  },
  yaxis: {
    fixedrange: true,
    zeroline: false,
    showgrid: true,
    tickfont: {
      size: 12,
      family: 'Lato',
      color: '#666666',
    },
    side: 'right',
    mirror: 'ticks',
    linecolor: '#c0c0c0',
    linewidth: 1,
    cliponaxis: false,
  },
};

export const singleInvestorFields = [
  {
    field: 'companyName',
    title: 'Investment',
    type: 'link-advanced',
    skipSorting: true,
  },
  {
    field: 'stakeholderName',
    title: 'Investor Name in Filings',
    type: 'text',
    skipSorting: true,
  },
  {
    field: 'classOfShares',
    title: 'Class of Shares',
    type: 'text',
    skipSorting: true,
  },
  {
    field: 'amountOfShares_text',
    title: 'Number of Shares',
    type: 'text',
    shouldComputed: true,
    skipSorting: true,
  },
  {
    field: 'CS01filingDate',
    title: 'Date Reported',
    type: 'text',
    isDate: true,
  },
  {
    field: 'currency',
    title: 'Currency',
    type: 'text',
    skipSorting: true,
  },
  {
    field: 'pricePerShare_text',
    title: 'Last Round Share Price',
    type: 'text',
    skipSorting: true,
  },
  {
    field: 'SH01filingDate',
    title: 'Date Reported',
    type: 'text',
    isDate: true,
  },
  {
    field: 'valueOfHoldings',
    title: 'Value of Holdings',
    type: 'amount',
    shouldComputed: true,
  },
];

export const periodTypes = [
  {
    id: 'period-filer-annual',
    value: 'annual',
    text: 'Annual',
  },
  {
    id: 'period-filer-monthly',
    value: 'monthly',
    text: 'Monthly',
  },
  {
    id: 'period-filer-quarterly',
    value: 'quarterly',
    text: 'Quarterly',
  },
];

export const monthPeriod = [
  {
    id: 1,
    value: 'january',
    text: 'January',
  },
  {
    id: 2,
    value: 'february',
    text: 'February',
  },
  {
    id: 3,
    value: 'march',
    text: 'March',
  },
  {
    id: 4,
    value: 'april',
    text: 'April',
  },
  {
    id: 5,
    value: 'may',
    text: 'May',
  },
  {
    id: 6,
    value: 'june',
    text: 'May',
  },
  {
    id: 7,
    value: 'july',
    text: 'July',
  },
  {
    id: 8,
    value: 'august',
    text: 'August',
  },
  {
    id: 9,
    value: 'september',
    text: 'September',
  },
  {
    id: 10,
    value: 'october',
    text: 'October',
  },
  {
    id: 11,
    value: 'November',
    text: 'November',
  },
  {
    id: 12,
    value: 'december',
    text: 'December',
  },
];

export const quarterlyPeriod = [
  {
    id: 1,
    value: 'q1',
    text: 'Q1',
  },
  {
    id: 2,
    value: 'q2',
    text: 'Q2',
  },
  {
    id: 3,
    value: 'q3',
    text: 'Q3',
  },
  {
    id: 4,
    value: 'q4',
    text: 'Q4',
  },
];

export const UKDisableMessage = (hasInsightsTrialAccess) => (hasInsightsTrialAccess
  ? (
    <>
      This feature is not available in trial mode.
      {' '}
      <a href="/contact-us">Contact us</a>
      {' '}
      to schedule a demo or move to a full plan.
    </>
  ) : (
    <>
      <Link to="/pricing">
        Upgrade to UK Insights
      </Link>
      {' '}
      to use this feature.
    </>
  )
);

export const defaultChangesChartBarData = {
  // set numbers as x values to got linear x-axis
  // and set actual values using tickers below with layout
  x: [],
  y: [],
  type: 'bar',
  hoverinfo: 'none',
  textinfo: 'none',
  unselected: {
    marker: {
      opacity: 0.6,
    },
  },
};

export const defaultChangesChartLayoutData = {
  autosize: true,
  dragmode: false,
  barmode: 'group',
  showlegend: false,
  hovermode: 'x',
  xaxis: {
    fixedrange: true,
    type: 'date',
    tickfont: {
      size: 10,
      family: 'Lato',
      color: '#292929',
    },
    zerolinecolor: '#C4C4C4',
    showline: false,
    tickformat: '%b<br />%Y',
    cliponaxis: false,
  },
  yaxis: {
    fixedrange: true,
    griddash: 'dash',
    gridcolor: '#E0E0E0',
    zerolinecolor: '#C4C4C4',
    showline: false,
    cliponaxis: false,
    tickfont: {
      size: 10,
      family: 'Lato',
      color: '#313131',
    },
  },
};

export const ChangesCompanyStatusTitle = {
  active: 'active',
  active_proposal: 'active (proposed to strike off)',
  liquidation: 'liquidation',
  administration: 'administration',
};

export const ChangesCompanyStatusValue = {
  active: 'active',
  'active (proposed to strike off)': 'active_proposal',
  liquidation: 'liquidation',
  administration: 'administration',
};

export const cumulativeChartColor = {
  active: '#5CB96E',
  'active (proposed to strike off)': '#FFB300',
  liquidation: '#E35657',
  administration: '#666',
};

export const ChangesCompanyStatusArr = [
  {
    id: 'active',
    title: 'active',
    value: 'active',
  },
  {
    id: 'active (proposed to strike off)',
    title: 'active (proposed to strike off)',
    value: 'active_proposal',
  },
  {
    id: 'liquidation',
    title: 'liquidation',
    value: 'liquidation',
  },
  {
    id: 'administration',
    title: 'administration',
    value: 'administration',
  },
];

export const defaultChangesChartPieData = {
  // set numbers as x values to got linear x-axis
  // and set actual values using tickers below with layout
  x: [],
  y: [],
  type: 'pie',
  hoverinfo: 'none',
  textinfo: 'none',
  direction: 'clockwise',
  sort: false,
  rotation: 45,
  hole: 0.6,
};

export const defaultChangesChartPieLayoutData = {
  autosize: true,
  dragmode: false,
  showlegend: false,
  margin: {
    t: 0, b: 0, l: 0, r: 0,
  },
  xaxis: {
    fixedrange: true,
    zeroline: false,
    cliponaxis: false,
  },
  yaxis: {
    fixedrange: true,
    zeroline: false,
    showgrid: false,
    showline: false,
    showticklabels: false,
    cliponaxis: false,
  },
  annotations: [
    {
      font: {
        size: 16,
        weight: 700,
      },
      showarrow: false,
      text: '',
    },
  ],
};
